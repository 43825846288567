.f-navbar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 1.5rem;
  position: sticky;
  top: 0;
  z-index: 40;
}

.q-navbar-links_logo{
margin-right: 1.5rem;
}

.q-navbar-links_logo img{
top: 70px;
left: 70px;
width: 100px;
height: 100px;
opacity: 1;
}

.f-logo-text{
display: flex;
align-items: center;
justify-content: center;
top: 159px;
left: 67px;
width: 48px;
height: 14px;
color: white;
text-align: center;
opacity: 0.5;
}

.f-navbar-links_container{
  display: flex;
  flex-direction: row;
}

.f-navbar-lang{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.blog-click {
  margin-left: 20px;
}

.nav-button{
  margin-left: 20px;
  width: 120px;
  min-height: 38px;
  font-size: 17px;
  background-color: transparent;
  border-radius: 5px 5px 5px 5px;
  border-color: white;
  color: white;
  border: 1px solid #FFFFFF;
}

.firecopy {
  position: relative;
  height: 119%;
  margin-left: 3dvw;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  overflow: visible;
  /* will-change: opacity; */
}

.f-video-container {
  /* backface-visibility: hidden; */
  width: 580px;
  height: 590px;
  /* width: 43%;
  height: 43%; */
  position: relative;
  z-index: 0; /*video is above the hexagon */
  transform: translate3d(0,0,0);
}

video {
  width: 100%; /* full width of the container */
  height: 100%; /* full height of the container */
  object-fit: cover; /* Resize to cover the container */
  background-color: transparent;
}

.color-overlay {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 95%;
  border-style: solid;
  border-color: black;
  pointer-events: none;
  background-color: var(--overlay-color);
  mix-blend-mode: color !important;
  -webkit-mix-blend-mode: color;
  -moz-mix-blend-mode: color;
  -ms-mix-blend-mode: color;
  -o-mix-blend-mode: color;
  transition: background-color 0.3s ease-in-out;
}

#color-overlay {
  mix-blend-mode: overlay !important;
}

.f-column {
  position: absolute;
  left: 0;
  right: 57%;
  width: 100%;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
 
.f-text-container {
  position: relative;
  padding: 20px;
  color: white;
  /* left: 5%;
  right: 5%; */
  width: 90%;
  height: 85%;
  opacity: 0;
  max-height: 80vh; /* Set a fixed height for mobile */
  overflow-y: auto; /* Enable vertical scrolling */
  z-index: 50;
  font-size: 18px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bottom-text {
  left: 42dvw;
  width: 100%;
  top: 78dvh;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  position: absolute;
  z-index: 151;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.hidden {
  display: none;
}
  
@keyframes slideToLeft {
  from {
    transform: translate(0%, 0%);
  }
  to {
    transform: translate(-50%, -5%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.f-video-container.moving {
  /* animation: slideToLeft 2s forwards;  */
  animation: slideToRight 2s forwards; 
}

.f-text-container.appearing {
  animation: fadeIn 2s forwards; 
}

.fading {
  animation: fadeOut 1s forwards;
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

.tilted-svg {
  /* animation: slideBottom 1s forwards; */
  transform: translate(148px, 23px);
}

.f-tilted {
width: 420px; 
height: 400px;
transform: rotate3d(1, 0, 0, 70deg);
/* transition: transform 2s ease-in-out; */
opacity: 0.3;
transform-style: preserve-3d;
}

.svg-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-svg-container {
top: 12%;
right: 12%;
transform: translateY(40%);
justify-self: center;
opacity: 0; /* Start hidden */
position: absolute;
transform-style: preserve-3d; /* Allow 3D transformations */ 
z-index: -1;
overflow: visible;
}

.f-svg-container.hidden {
opacity: 0;
transition: opacity 1s ease-in-out;
}

.f-svg-container.rotate {
transition: transform 1s ease-in-out;
}

svg {
  width: 420px; 
  height: 400px;
  margin: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transform-style: preserve-3d;
  overflow: visible;
}

svg {
  transform-origin: center center;
  /* transition: transform 1s ease-in-out; */
}

@keyframes slideToRight {
  from { transform: translate(0%, 0%); }
  to { transform: translate(273px, -20px); }
}

.f-video-container.slide-right {
  animation: slideToRight 3s forwards;
}

.slide-bottom {
  animation: slideBottom 2s forwards;
}

@keyframes slideBottom {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(148px, 25px);
  }
}

.rotate-0 {
  transform: perspective(1000px) rotate3d(0, -2.747, 1, 0deg);
}

.rotate-30 {
  transform: perspective(1000px) rotate3d(0, -2.747, 1, 30deg);
}

.rotate-60 {
  transform: perspective(1000px) rotate3d(0, -2.747, 1, 60deg);
}

.text-left {
  width: 35%;
  color: white;
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Delayed fade effect */
  top: 0;
  left: 120px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  text-align: center;
  position: absolute;
  z-index: 2;
  justify-content: center; 
}

.third-line {
  text-align: end;
}

.insight-button {
  background-color: var(--maxColor);
  color: black;
  margin-top: 30px;
  padding: 12px 24px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 0 20px var(--maxColor);
  transition: transform 0.2s ease;
  font-weight: normal;
}

.insight-button:hover {
  transform: translateY(-2px);
}

.left-line1 {
  font-size: 50px;
  font-weight: bold;
}
.left-line2 {
  font-size: 31px;
  font-weight: bold;
}
.left-line3 {
  height: 150px;
  padding-top: 30px;
  font-size: 16px;
  text-align: center;
}
.left-lastline1 {
  padding-top: 40px;
  font-size: 15px;
  opacity: 0.6;
}
.left-lastline2 {
  font-size: 12px;
  text-align: center;
  opacity: 0.6;
}

.f-main-progress-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.f-main-progress-container {
  width: 400px;
  height: 15px;
  margin-left: 10px;
  background-color: #ccc;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.f-main-progress-fill {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.3s ease-in-out;
}

.f-main-progress-percentage {
  margin-left: 10px; 
}

.arrow {
  color: white;
  position: relative;
  font-size: 24px;
  transition: opacity 1s ease-in-out;
  top: 100px;
  z-index: 100;
}

.left-arrow {
  opacity: 0;
  background-color: transparent;
  right: 245px;
}

.right-arrow {
  opacity: 0;
  background-color: transparent;
  left: 150px;
}

.f-legend-container {
  position: absolute;
  width: 250px;
  top: 20px;
  right: 60px;
  z-index: 99;
  background-color: transparent;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}

.f-legend-container .score-list li,
.f-legend-container .progress-container {
  opacity: 1.0;
}

.f-score-list {
  list-style: none;
  margin-top: 10px;
  padding: 0;
}

.f-score-list li {
  display: flex;
  align-items: center;
  opacity: 0.7;
  margin-bottom: 5px;
}

.f-score-list li.active {
  opacity: 1;
}

.f-progress-container {
  width: 150px;
  flex-grow: 1;
  height: 12px;
  background-color: #1a1a1a;
  margin-right: 10px;
  margin-left: 15px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  opacity: 0.7;
}

li.active .f-progress-container {
opacity: 1;
}

.f-progress-fill {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}

.f-percentage {
color: white;
font-size: 13px;
}

.f-label-color-0 {
color: rgb(0, 255, 0);
}

.f-label-color-1 {
color: rgb(0, 0, 255);
}

.f-label-color-2 {
color: rgb(0, 255, 255);
}

.f-label-color-3 {
color: rgb(255, 255, 0);
}

.f-label-color-4 {
color: rgb(255, 0, 0);
}

.f-label-color-5 {
color: rgb(255, 0, 255);
}

.f-color-0 {
  background-color: rgb(0, 255, 0);
}

.f-color-1 {
  background-color: rgb(0, 0, 255);
}

.f-color-2 {
  background-color: rgb(0, 255, 255);
}

.f-color-3 {
  background-color: rgb(255, 255, 0);
}

.f-color-4 {
  background-color: rgb(255, 0, 0);
}

.f-color-5 {
  background-color: rgb(255, 0, 255);
}

.f-small-text {
  position: absolute; 
  top: 580px;
  right: 90px;
  color: #fff;
  opacity: 0;
  transition: opacity 1.5s ease-in-out; 
  z-index: 100; 
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.f-back-button {
  color: white;
  position: fixed;
  bottom: 10px;
  left: 50px;
}

.f-home-button {
  color: white;
  position: absolute;
  bottom: 0px;
  right: 50px;
}

.f-new-content {
  display: flex;
  position: absolute;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: white;
  opacity: 0;
  animation: fadeIn 1s forwards;
  z-index: 99;
}

.f-left-content, .f-right-content {
  padding: 20px;
}

.hex-code {
  font-size: 50px;
  color: rgb(var(--red), var(--green), var(--blue));
  text-align: center;
  line-height: 1;
  padding: 5px;
  text-shadow: 0 0 3px rgb(var(--red), var(--green), var(--blue)),
               0 0 8px rgb(var(--red), var(--green), var(--blue));
}

.gain-insight-text {
  width: 90%;
  font-size: 20px;
}

.f-colored-square {
  width: 100px;
  height: 100px;
  background-color: #007BFF;
  margin-top: 0px;
  margin-bottom: 10px;
}

.text-button{
  width: 100px;
  background-color: #1a1a1a;
  color: white;
  padding: 7px 5px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 5px;
}

.green-bordered-input {
  border: 2px solid green;
  margin-bottom: 10px;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.check-text {
  margin-bottom: 10px;
  text-align: start;
  display: flex;
  align-items: center;
}

.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 17px;
  height: 17px;
  border: 2px solid rgb(var(--red), var(--green), var(--blue));
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;
}

.custom-checkbox:checked {
  background-color: rgb(var(--red), var(--green), var(--blue));
}

.custom-checkbox:focus {
  box-shadow: 0 0 0 2px rgba(0, 128, 0, 0.3);
}

.checkbox-label {
  cursor: pointer;
  padding-left: 5px;
}

.combined-content-wrapper {
  position: absolute;
  left: 7%;
  width: 47%;
  height: 90%;
  border: 1px solid #FFFFFF;
  padding: 20px;
  display: flex;
  flex-direction: column;
  z-index: 50;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}

.content-wrapper {
  display: flex; 
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 80%;
  height: 100%;
}

.register-section,
.potential-section,
.app-section {
  width: 100%;
  margin-bottom: 30px;
}

.potential-section {
  margin-bottom: 40px;
}

.potential-section p {
  font-size: 20px;
}

.app-section {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.strength,
.weakness {
  flex: 1;
}

.app-content {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.app-left,
.app-right {
  display: flex;
  flex-direction: column;
}

.app-left {
  margin-right: 10px;
  align-items: center;
}

.app-right {
  margin-left: 10px;
}

.receive-result {
  font-size: 30px;
  padding-bottom: 15px;
}

.f-hr {
  width: 100%;
  color: white;
  opacity: 0.25;
}

.potential-section {
  font-size: 16px;
}

.live-up {
  font-size: 26px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.app-helm-icon {
  width: 60px; 
  height: auto; 
  max-width: 100%;
}

.f-ios-icon {
  height: 40px;
  width: 115px;
}
.f-android-icon {
  height: 50px;
  width: 130px;
}

.f-inputBox {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 3dvh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid green;
  border-radius: 20px;
  padding: 8px;
  background: transparent;
}

#f-inputID::placeholder {
  width: 70%;
  color: white;
  opacity: 0.5;
  background-color : transparent;
  border: none;
  outline: none;
}

.register {
  color: green;
  cursor: pointer;
  font-weight: 100;
  padding-right: 20px;
}

.registered-green {
  color: green;
}

.invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  line-height: 0;
  margin-bottom: 15px;
  text-align: center;
}

.close-result {
display: none;
}

.read_more {
display: none;
}

.views {
display: none;
}

@keyframes blink {
50% {
  opacity: 0;
}
}

.checkbox-label.blink {
animation: blink 3s linear infinite;
}

.save-fire-images {
flex-direction: row;
}

.example-wrapper {
display: none;
}

.example-container {
display: none;
width: 400px;
height: 400px;
position: relative;
z-index: 0;
display: inline-block;
margin-top: 30px;
margin-bottom: 30px;
}

.example-container::before {
display: none;
content: '';
position: absolute;
top: 0;
left: 0;
width: 400px;
height: 400px;
pointer-events: none;
}

.example-color-overlay {
position: absolute;
top: 0;
left: 0;
width: 400px;
height: 400px;
pointer-events: none;
}

.example-color-overlay:before {
content: '';
display: block;
width: 400px;
height: 400px;
background-color: var(--overlay-color);
mix-blend-mode: color;
}

.example-hex-code {
position: absolute;
bottom: 0;
left: 50%;
transform: translateX(-50%);
font-size: 21px;
text-align: center;
line-height: 0.5;
}

.example-legend-container {
position: absolute;
width: 100px;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
z-index: 99;
background-color: transparent;
padding: 15px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
opacity: 0;
transition: opacity 1.5s ease-in-out;
}

.label-text {
  overflow: visible;
  /* line-height: normal; */
}

.rect {
  display: flex;
  flex-grow: 0; 
  flex-shrink: 0;
}

/* New styles for new design */
/* New styles for new design */

.overlay-content {
  position: absolute;
  top: 0;
  left: 3dvw;
  right: 2dvw;
  width: 95%;
  height: 100%;
  display: flex;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: 2;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  margin-left: 10dvw;
  position: relative;
  width: 100%;
  border-radius: 60px;
}

.musashi-image {
  aspect-ratio: 12/17;
  width: auto;
  height: 70vh;
  object-fit: cover;
  box-shadow: 0 0 20px var(--maxColor);
  animation: glowPulse 2s infinite;
  border-radius: 60px;
}

@keyframes glowPulse {
  0% {
    box-shadow: 0 0 20px var(--maxColor);
  }
  50% {
    box-shadow: 0 0 30px var(--maxColor);
  }
  100% {
    box-shadow: 0 0 20px var(--maxColor);
  }
}

.quote-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-left: 8dvw;
  padding-right: 12dvw;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.resemble-text {
  font-size: 2rem;
  color: white;
  font-weight: bold;
}

.figure-text {
  font-size: 3rem;
  color: white;
  margin-bottom: 1rem;
  font-weight: bold;
  color: var(--maxColor);
}

.quote-text {
  font-size: 2rem;
  color: white;
  line-height: 1.2;
  font-weight: bold;
  color: var(--maxColor);
}

.f-video-container {
  transition: filter 1s ease-in-out;
}

.save-result {
  font-size: 30px;
  text-align: center;
}

.save-button {
  width: 25px;
  position: absolute;
  top: 0px;
  right: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 100;
}

.save-button.active {
  filter: drop-shadow(0 0 4px rgb(var(--red), var(--green), var(--blue)));
}

.save-notification {
  position: absolute;
  justify-content: center;
  top: 5dvh;
  right: 8dvw;
  padding: 10dvh 0dvw;
  border: 1px solid #39FF14;
  border-radius: 4px;
  color: white;
  width: 35dvw;
  height: auto;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 100;
  /* Add backdrop blur effect */
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background: rgba(0, 0, 0, 0.4);
}

.save-notification.visible {
  opacity: 1;
}

/* Blurred effect for cross-browser compatibility */
@supports not (backdrop-filter: blur(8px)) {
  .save-notification {
      background: rgba(0, 0, 0, 0.8);
  }
}


@media (min-width: 608px) and (max-width: 1023px) {
/* 768 */
.navbar-links {
  display: none;
}

.f-logo {
  height: 50px;
}

.navbar-menu {
  display: block;
}

.f-navbar-lang{
  display: none;
}

.m-nav-button{
  cursor: pointer; 
  margin-top: 30px;
  width: 120px;
  min-height: 38px;
  font-size: 17px;
  background-color: transparent;
  border-radius: 5px 5px 5px 5px;
  border-color: white;
  color: white;
  border: 1px solid #FFFFFF;
}

.nav-item a {
  margin-right: 0px;
  font-size: 25px;
  opacity: 0.2;
}

.mobile-blog-click {
  font-size: 23px;
  padding-top: 20px;
}

.m-navbar-icons {
  display: flex; 
  position: relative;
  flex-direction: row;
  align-items: center;
  padding-top: 30px;
}

.m-navbar-icon {
  width: 30px;
  margin: 10px 30px;
}

.dropdown {
  position: relative;
  padding-top: 20px;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: black;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdown-menu li {
  padding: 10px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-menu li:hover {
  background-color: black;
  opacity: 0.6;
}

.dropdown-menu li span {
  margin-right: 10px;
}

.body, html {
  height: 100%;
  margin: 0;
  overflow-y: auto; /* Ensures the page is scrollable */
}

.firecopy {
  position: absolute;
  justify-content: center;
  margin-left: auto;
  align-items: center;
  text-align: center;
  padding-top: 0px;
  max-height: 76vh;
  width: 100%;
  height: auto;
}

.f-video-container {
  width: 90%;
  height: 90%;
  left: 5%;
  right: 5%;
  position: relative;
  justify-content: center;
  display: flex;
  justify-self: center;
}

.f-video-container::before {
  top: 0;
  left: 0;
  right: 0;
  width: 85%;
  height: 80%;
  display: flex;
}

@keyframes slideToLeft {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(0%);
  }
}

.f-column {
  position: absolute;
  display: flex;
  right: 0;
  left: 0;
  width: 100%;
  top: 0;
  flex-direction: column;
}
.f-text-container {
  position: absolute;
  margin-top: 0px;
  padding: 30px;
  color: white;
  width: 90%;
  right: 30px;
  left: 30px;
  opacity: 0;
  height: 90dvh;
  max-height: 90dvh;
  min-height: 90dvh;
  z-index: 151;
  text-align: start;
  justify-content: center;
}

.bottom-text {
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  top: 82dvh;
  justify-content: center;
}

.f-video-container.slide-right {
  animation: slideToRight 3s forwards;
}

@keyframes slideToRight {
  from {
    transform: translateY(0%);
    scale: 100%;
  }
  to {
    transform: translateY(-57%); 
    scale: 50%;
  }
}

.f-tilted {
  width: 100%;
  height: 100%;
  -webkit-transform: rotate3d(1, 0, 0, 70deg);
  -webkit-transition: transform 2s ease-in-out;
  transform: rotate3d(1, 0, 0, 70deg);
  transition: transform 2s ease-in-out;
  opacity: 0.5;
  -webkit-transform-style: preserve-3d; /* Allow 3D transformations */
  transform-style: preserve-3d; /* Allow 3D transformations */
  justify-content: center;
  transform-origin: center center;
}

.f-tilted-reset {
  /* -webkit-transform: translate(-70vw, -33dvh) scale(80%);
  transform: translate(-50vw, -33dvh) scale(80%); */
  -webkit-transition: transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
  opacity: 0.5;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform-origin: center center;
}

@keyframes slideBottom {
  from {
    transform: translate(0vw, 0dvh);
  }
  to {
    transform: translate(0vw, -5dvh) scale(75%);
  }
}

.force-quotient {
  position: absolute;
  top: 77dvh;
}

.left-lastline1,
.left-lastline2 {
  text-align: center;
  font-size: 12px;
}

.f-legend-container {
  right: 20%;
  left: 20%;
  scale: 80%;
  width: 60%;
  top: 50%;
}

.f-legend-container .score-list li,
.f-legend-container .progress-container {
  opacity: 1.0;
}

.tilted-svg {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 60%;
  height: 100%;
  transform: translate(0px, -7dvh);
}

.svg-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0%;
  pointer-events: none;
  list-style-type: none;
}

.f-svg-container {
  z-index: 0;
  top: 2%;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  opacity: 0; /* Start hidden */
  -webkit-transform-style: preserve-3d; /* Allow 3D transformations */ 
  transform-style: preserve-3d; /* Allow 3D transformations */ 
  scale: 60%;
  right: 0%;
  overflow: visible;
  justify-self: center;
  pointer-events: all;
}

.f-score-list {
  list-style: none;
}

.f-score-list li {
  display: flex;
  align-items: center;
  opacity: 1.0;
  margin-bottom: 10px;
}

.f-small-text {
  justify-content: center;
  position: fixed; 
  top: 80dvh;
  right: 30px;
  /* left: 0px;  */
  color: #fff; 
  opacity: 0;
  transition: opacity 1.5s ease-in-out; 
  z-index: 100; 
}

.read_more {
  color: black;
  display: flex;
  position: absolute;
  top: 80dvh;
  opacity: 0;
  background-color: white;
  color: black;
  padding: 8px 20px;
  border-radius: 25px;
  cursor: pointer;
}

.text-left {
  width: 70%;
  top: -20%;
  left: 15%;
  right: 15%;
  bottom: 0px;
}

.text-left-lines {
  display: flex;
  flex-wrap: wrap;
  padding-top: 38dvh;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.arrow {
  display: none;
}

.left-arrow {
  display: none;
}

.right-arrow {
  display: none;
}

.views {
  position: absolute;
  display: flex;
  color: white;
  font-size: 24px;
  margin-top: 50px;
  z-index: 150;
}

.views img {
  width: 17px;
}

.left-view {
  z-index: 150;
  margin-right: 150px;
  cursor: pointer;
}

.right-view {
  z-index: 150;
  margin-left: 150px;
  cursor: pointer;
}

.left-line1 {
  order: 1;
  font-size: 50px;
  font-weight: bold;
}
.left-line2 {
  order: 2;
  font-size: 31px;
  font-weight: normal;
  opacity: 0.25;
}
.left-line3 {
  order: 4;
  padding-top: 30px;
  font-size: 15px;
  text-align: center;
}

.f-main-progress-wrapper {
  order: 3;
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.f-main-progress-container {
  width: 300px;
  height: 15px;
  background-color: #ccc;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.f-main-progress-fill {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.3s ease-in-out;
}

.f-main-progress-percentage {
  margin-left: 10px;
}

.close-result {
  position: absolute;
  width: 30px;
  opacity: 0;
  top: 78dvh;
  cursor: pointer;
  display: flex;
  z-index: 153;
  bottom: 0;
}

.f-new-content {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 80vw;
  margin: 50px auto 0 auto;
  color: white;
  opacity: 0;
  animation: fadeIn 1s forwards;
  z-index: 152;
}

.f-left-content, .f-right-content {
  padding: 0px;
  padding-top: 30px;
}

.f-right-content {
  width: 88vw;
  padding-left: 6vw;
  padding-right: 6vw;
}

.hex-code {
  margin-top: 20px;
  margin-left: 20dvw;
  margin-right: 20dvw;
  font-size: 28px;
  font-weight: bold;
  border: 2px solid rgb(var(--red), var(--green), var(--blue));
  line-height: 1;
  padding: 15px 15px;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.f-colored-square {
  width: 100px;
  height: 100px;
  background-color: #007BFF;
  margin-top: 20px;
  margin-bottom: 10px;
}

.text-button{
  width: 100px;
  margin-top: 10px;
  background-color: #1a1a1a;
  color: white;
  padding: 7px 5px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.green-bordered-input {
  border: 2px solid green;
  margin-bottom: 10px;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.check-text {
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid rgb(var(--red), var(--green), var(--blue));
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;
}

.custom-checkbox:checked {
  background-color: rgb(var(--red), var(--green), var(--blue));
}

.custom-checkbox:focus {
  box-shadow: 0 0 0 2px rgba(0, 128, 0, 0.3);
}

.checkbox-label {
  cursor: pointer;
  padding-left: 5px;
}

.f-inputBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 7px;
  background: transparent;
}

#f-inputID::placeholder {
  width: 70%;
  color: white;
  opacity: 0.5;
  background-color : transparent;
  border: none;
  outline: none;
  font-size: 14px;
}


.register {
width: 20%;
color: green;
cursor: pointer;
font-weight: 100;
margin-right: 0px;
padding-right: 0px;
font-size: 12px;
}

.invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  line-height: 0;
  margin-bottom: 15px;
}

.f-back-button {
color: white;
position: absolute;
top: 80vh;
left: 50px;
z-index: 153;
}

.f-home-button {
color: white;
position: absolute;
top: 80vh;
right: 50px;
z-index: 153;
}

.f-video-container.slide-bottom-big {
animation: slideBottomBig 1s forwards;
}

@keyframes slideBottomBig {
from {
  transform: translateY(0%);
  scale: 100%;
}
to {
  transform: translateY(10%); 
  scale: 200%;
}
}

.combined-content-wrapper {
  position: absolute;
  left: auto;
  width: 90dvw;
  height: 78dvh;
  border: 1px solid #FFFFFF;
  padding: 0px;
  margin-top: 15dvh;
  display: flex;
  flex-direction: column;
  z-index: 50;
  align-items: center;
  justify-content: center;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 80%;
}

.register-section,
.potential-section,
.app-section {
  width: 100%;
  margin-bottom: 30px;
}

.potential-section {
  margin-bottom: 40px;
}

.app-section {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.strength,
.weakness {
  flex: 1;
}

.app-content {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.app-left,
.app-right {
  display: flex;
  flex-direction: column;
}

.app-left {
  margin-right: 10px;
}

.app-right {
  margin-left: 10px;
}

.receive-result {
  font-size: 30px;
  padding-bottom: 15px;
}

.f-hr {
  width: 100%;
  color: white;
  opacity: 0.25;
}

.potential-section {
  font-size: 16px;
}

.live-up {
  font-size: 26px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.app-helm-icon {
  width: 60px; 
  height: auto; 
  max-width: 100%;
}

.f-ios-icon {
  height: 40px;
  width: 115px;
}
.f-android-icon {
  height: 50px;
  width: 130px;
}

.overlay-content {
  justify-content: center;
  flex-direction: column;
  top: auto;
  left: auto;
  align-items: center;
  padding-top: 10dvh;
}

.image-container {
  padding: 1rem;
  margin-left: auto;
  border-radius: 20px;
}

.musashi-image {
  height: 50vh; /* adjust this value to control the overall size */
  border-radius: 20px;
}

.quote-container {
  padding: 1rem;
}

.resemble-text {
  font-size: 1.5rem;
  opacity: 0.8;
}

.figure-text {
  font-size: 2.2rem;
  font-weight: 600;
}

.quote-text {
  width: 60dvw;
  font-size: 1.3rem;
}

.gain-insight-text {
  font-size: 15px;
}

.save-result {
  font-size: 25px;
}

.save-notification {
  position: absolute;
  justify-content: center;
  top: 15dvh;
  right: auto;
  padding: 5dvh 0dvw;
  border: 1px solid #39FF14;
  border-radius: 4px;
  color: white;
  width: 80dvw;
  height: auto;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 100;
  /* Add backdrop blur effect */
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background: rgba(0, 0, 0, 0.9);
}

}

@media screen and (max-width: 608px) {
.navbar-links {
  display: none;
}

.f-logo {
  height: 50px;
}

.navbar-menu {
  display: block;
}

.f-navbar-lang{
  display: none;
}

.m-nav-button{
  cursor: pointer; 
  margin-top: 30px;
  width: 120px;
  min-height: 38px;
  font-size: 17px;
  background-color: transparent;
  border-radius: 5px 5px 5px 5px;
  border-color: white;
  color: white;
  border: 1px solid #FFFFFF;
}

.nav-item a {
  margin-right: 0px;
  font-size: 25px;
  opacity: 0.2;
}

.mobile-blog-click {
  font-size: 23px;
  padding-top: 20px;
}

.m-navbar-icons {
  display: flex; 
  position: relative;
  flex-direction: row;
  align-items: center;
  padding-top: 30px;
}

.m-navbar-icon {
  width: 30px;
  margin: 10px 30px;
}

.dropdown {
  position: relative;
  padding-top: 20px;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: black;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdown-menu li {
  padding: 10px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-menu li:hover {
  background-color: black;
  opacity: 0.6;
}

.dropdown-menu li span {
  margin-right: 10px;
}

.body, html {
  height: 100%;
  margin: 0;
  overflow-y: auto; /* Ensures the page is scrollable */
}

.firecopy {
  position: absolute;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  /* left: auto; */
  text-align: center;
  padding-top: 10px;
  max-height: 76vh;
  width: 100%;
  height: auto;
}

.f-video-container {
  width: 100%;
  height: 100%;
  left: 0px;
  right: 0px;
  position: relative;
  justify-content: center;
  display: flex;
}

.f-video-container::before {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 95%;
  display: flex;
}

@keyframes slideToLeft {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(0%);
  }
}

.f-column {
  position: absolute;
  display: flex;
  right: 0;
  left: 0;
  width: 100%;
  top: 10dvh;
  flex-direction: column;
}

.f-text-container {
  position: absolute;
  margin-top: 0px;
  padding: 30px;
  color: white;
  width: 90%;
  right: 30px;
  left: 30px;
  opacity: 0;
  height: 90dvh;
  max-height: 90dvh;
  min-height: 90dvh;
  /* overflow-y: auto; */
  z-index: 151;
  text-align: start;
  justify-content: center;
}

.bottom-text {
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0dvh;
  top: 81dvh;
  justify-content: center;
}

.f-video-container.slide-right {
  animation: slideToRight 3s forwards;
}

@keyframes slideToRight {
  from {
    transform: translateY(0%);
    scale: 100%;
  }
  to {
    transform: translateY(-59%); 
    scale: 60%;
  }
}

.f-tilted {
  width: 100%;
  height: 100%;
  -webkit-transform: rotate3d(1, 0, 0, 70deg);
  -webkit-transition: transform 2s ease-in-out;
  transform: rotate3d(1, 0, 0, 70deg);
  transition: transform 2s ease-in-out;
  opacity: 0.5;
  -webkit-transform-style: preserve-3d; /* Allow 3D transformations */
  transform-style: preserve-3d; /* Allow 3D transformations */
  justify-content: center;
  transform-origin: center center;
}

.f-tilted-reset {
  /* -webkit-transform: translate(-70vw, -33dvh) scale(80%);
  transform: translate(-50vw, -33dvh) scale(80%); */
  -webkit-transition: transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
  opacity: 0.5;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform-origin: center center;
}

@keyframes slideBottom {
  from {
    transform: translate(0vw, 0dvh);
  }
  to {
    transform: translate(0vw, -7dvh) scale(75%);
  }
}

.force-quotient {
  position: absolute;
  top: 62dvh;
}

.left-lastline1,
.left-lastline2 {
  text-align: center;
  font-size: 12px;
}

.f-legend-container {
  right: 15vw;
  left: 15vw;
  scale: 80%;
  width: 70vw;
  top: 31dvh;
}

.f-legend-container .score-list li,
.f-legend-container .progress-container {
  opacity: 1.0;
}

.tilted-svg {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(0px, -7dvh);
}

.svg-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0%;
  pointer-events: none;
  list-style-type: none;
}

.f-svg-container {
  z-index: 0;
  top: 2%;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  opacity: 0; /* Start hidden */
  -webkit-transform-style: preserve-3d; /* Allow 3D transformations */ 
  transform-style: preserve-3d; /* Allow 3D transformations */ 
  scale: 60%;
  right: 0%;
  overflow: visible;
  justify-self: center;
  pointer-events: all;
}

.f-score-list {
  list-style: none;
}

.f-score-list li {
  display: flex;
  align-items: center;
  opacity: 1.0;
  margin-bottom: 10px;
}

.f-small-text {
  justify-content: center;
  position: fixed; 
  top: 80dvh;
  right: 30px;
  color: transparent;
  opacity: 0;
  z-index: 100;
  display: none !important;
}

.f-small-text-mobile {
  justify-content: center;
  position: fixed; 
  top: 80dvh;
  right: 30px;
  color: #fff; 
  opacity: 1;
  transition: opacity 1.5s ease-in-out; 
  z-index: 100; 
}

.read_more {
  color: black;
  display: flex;
  position: absolute;
  top: 79dvh;
  opacity: 0;
  background-color: white;
  color: black;
  padding: 8px 20px;
  border-radius: 25px;
  cursor: pointer;
}

.text-left {
  width: 80%;
  top: -10%;
  left: 10%;
  right: 10%;
  bottom: 0px;
}

.text-left-lines {
  display: flex;
  flex-wrap: wrap;
  padding-top: 38dvh;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.arrow {
  display: none;
}

.left-arrow {
  display: none;
}

.right-arrow {
  display: none;
}

.views {
  position: absolute;
  display: flex;
  color: white;
  font-size: 20px;
  margin-top: 50px;
  z-index: 150;
  opacity: 0;
}

.views img {
  width: 15px;
}

.left-view {
  z-index: 150;
  margin-right: 150px;
  cursor: pointer;
}

.right-view {
  z-index: 150;
  margin-left: 150px;
  cursor: pointer;
}

.left-line1 {
  order: 1;
  font-size: 46px;
  font-weight: bold;
}
.left-line2 {
  order: 2;
  font-size: 28px;
  font-weight: normal;
  opacity: 0.25;
}
.left-line3 {
  order: 4;
  padding-top: 30px;
  font-size: 15px;
  text-align: center;
}

.f-main-progress-wrapper {
  order: 3;
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.f-main-progress-container {
  width: 280px;
  height: 12px;
  background-color: #ccc;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.f-main-progress-fill {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.3s ease-in-out;
}

.f-main-progress-percentage {
  font-size: 14px;
  margin-left: 10px;
}

.close-result {
  position: absolute;
  width: 25px;
  opacity: 0;
  top: 80dvh;
  cursor: pointer;
  display: flex;
  z-index: 153;
  bottom: 0;
  left: 10dvw;
}

.f-new-content {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 80vw;
  margin: 50px auto 0 auto;
  color: white;
  opacity: 0;
  animation: fadeIn 1s forwards;
  z-index: 152;
}

.f-left-content, .f-right-content {
  padding: 0px;
  padding-top: 30px;
}

.f-right-content {
  width: 88vw;
  padding-left: 6vw;
  padding-right: 6vw;
}

.hex-code {
  margin-top: 20px;
  margin-left: 20dvw;
  margin-right: 20dvw;
  font-size: 28px;
  font-weight: bold;
  border: 2px solid rgb(var(--red), var(--green), var(--blue));
  line-height: 1;
  padding: 15px 15px;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.f-colored-square {
  width: 100px;
  height: 100px;
  background-color: #007BFF;
  margin-top: 20px;
  margin-bottom: 10px;
}

.text-button{
  width: 100px;
  background-color: #1a1a1a;
  color: white;
  margin-top: 10px;
  padding: 7px 5px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.green-bordered-input {
  border: 2px solid green;
  margin-bottom: 10px;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.check-text {
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid rgb(var(--red), var(--green), var(--blue));
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;
}

.custom-checkbox:checked {
  background-color: rgb(var(--red), var(--green), var(--blue));
}

.custom-checkbox:focus {
  box-shadow: 0 0 0 2px rgba(0, 128, 0, 0.3);
}

.checkbox-label {
  cursor: pointer;
  padding-left: 5px;
}

.f-inputBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 7px;
  background: transparent;
}

#f-inputID::placeholder {
  width: 70%;
  color: white;
  opacity: 0.5;
  background-color : transparent;
  border: none;
  outline: none;
  font-size: 14px;
}

.register {
  width: 20%;
  color: green;
  cursor: pointer;
  font-weight: 100;
  margin-right: 0px;
  padding-right: 0px;
  font-size: 12px;
}

.invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  line-height: 0;
  margin-bottom: 15px;
}

.f-back-button {
color: white;
position: absolute;
top: 80vh;
left: 50px;
z-index: 153;
}

.f-home-button {
color: white;
position: absolute;
top: 80vh;
right: 50px;
z-index: 153;
}

.rotating-button {
  transition: transform 0.3s ease;
}

.rotating-button.plus {
  transform: rotate(45deg);
}

.rotating-button.x {
  transform: rotate(0deg);
}

.f-video-container.slide-bottom-big {
animation: slideBottomBig 1s forwards;
}

@keyframes slideBottomBig {
from {
  transform: translateY(0%);
  scale: 100%;
}
to {
  transform: translateY(10%); 
  scale: 200%;
}
}

.combined-content-wrapper {
  position: absolute;
  left: auto;
  width: 90dvw;
  height: 78dvh;
  border: 1px solid #FFFFFF;
  padding: 0px;
  margin-top: 15dvh;
  display: flex;
  flex-direction: column;
  z-index: 50;
  align-items: center;
  justify-content: center;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.register-section,
.potential-section,
.app-section {
  width: 100%;
  margin-bottom: 10px;
}

.potential-section {
  margin-bottom: 20px;
}

.personalize {
  font-size: 15px;
}

.app-section {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
}

.strength,
.weakness {
  flex: 1;
  font-size: 14px;
}

.app-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  flex-direction: row;
}

.app-left,
.app-right {
  display: flex;
  flex-direction: column;
}

.app-left {
  margin-right: 10px;
}

.app-right {
  margin-left: 10px;
}

.receive-result {
  font-size: 24px;
  padding-bottom: 5px;
}

.f-hr {
  width: 100%;
  color: white;
  opacity: 0.25;
}

.potential-section p {
  font-size: 16px;
}

.live-up {
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.get-app {
  display: none;
}

.app-helm-icon {
  width: 55px; 
  height: auto; 
}

.f-ios-icon {
  height: 35px;
  width: 105px;
}
.f-android-icon {
  height: 50px;
  width: 120px;
}

.overlay-content {
  justify-content: center;
  flex-direction: column;
  top: auto;
  left: auto;
  align-items: center;
  padding-top: 10dvh;
}

.image-container {
  padding: 1rem;
  margin-left: auto;
  border-radius: 20px;
}

.musashi-image {
  height: 45vh; /* adjust this value to control the overall size */
  border-radius: 20px;
}

.quote-container {
  padding: 1rem;
}

.resemble-text {
  font-size: 1.5rem;
  opacity: 0.8;
}

.figure-text {
  font-size: 2.2rem;
  font-weight: 600;
}

.quote-text {
  width: 60dvw;
  font-size: 1.3rem;
}

.gain-insight-text {
  font-size: 15px;
}

.save-result {
  font-size: 25px;
}

.save-notification {
  position: absolute;
  justify-content: center;
  top: 15dvh;
  right: auto;
  padding: 5dvh 0dvw;
  border: 1px solid #39FF14;
  border-radius: 4px;
  color: white;
  width: 80dvw;
  height: auto;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 100;
  /* Add backdrop blur effect */
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background: rgba(0, 0, 0, 0.9);
}
}